document.onreadystatechange = function () {
    switch (document.readyState) {
        case "loading":
            // The document is still loading.
            break;
        case "interactive":
            // The document has finished loading. We can now access the DOM elements.
            // But sub-resources such as images, stylesheets and frames are still loading.
            break;
        case "complete":
            // The page is fully loaded.
            hideLoader();
            break;
    }
};

const hideLoader = () => {
    setTimeout(() => {
        document.getElementById("loader").style.visibility = "hidden";
    }, 1000);
};
